<template>
  <date-range-picker
    ref="picker"
    v-model="pickerDates"
    :alwaysShowCalendars="alwaysShowCalendars"
    :singleDatePicker="calendarMode"
    :autoApply="autoApply"
    :localeData="localeData"
    :ranges="ranges"
    @update="updateValues"
    :opens="opens"
  >
    <template v-slot:input="picker">
      <span
        v-if="
          new Date(picker.startDate.setHours(0, 0, 0, 0)).getTime() ==
            new Date(picker.endDate.setHours(0, 0, 0, 0)).getTime()
        "
      >
        {{ picker.startDate | formatDate }}
      </span>
      <span v-else>
        {{ picker.startDate | formatDate }} -> {{ picker.endDate | formatDate }}
      </span>
    </template>
  </date-range-picker>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "date-range",
  props: {
    from: {
      default: moment().toDate()
    },
    to: {
      default: moment().toDate()
    },
    calendarMode: {
      //single | range
      default: "range"
    },
    opens: {
      //"center", "left", "right" or "inline"
      default: "right"
    }
  },

  components: {
    DateRangePicker
  },
  data() {
    return {
      pickerDates: {
        startDate: moment(this.from, "YYYY-MM-DD").toDate(),
        endDate: moment(this.to, "YYYY-MM-DD").toDate()
      },
      autoApply: true,
      alwaysShowCalendars: true,
      localeData: {
        direction: "ltr",
        weekLabel: "W",
        customRangeLabel: "Tùy chỉnh",
        daysOfWeek: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        monthNames: [
          "Tháng 1",
          "Tháng 2",
          "Tháng 3",
          "Tháng 4",
          "Tháng 5",
          "Tháng 6",
          "Tháng 7",
          "Tháng 8",
          "Tháng 9",
          "Tháng 10",
          "Tháng 11",
          "Tháng 12"
        ],
        firstDay: 0
      },
      ranges: null,
      rangesDefault: {
        "Hôm nay": [new Date(), new Date()],
        "Hôm qua": [
          new Date(moment().subtract(1, "day")),
          new Date(moment().subtract(1, "day"))
        ],
        "Hôm kia": [
          new Date(moment().subtract(2, "day")),
          new Date(moment().subtract(2, "day"))
        ],
        "Tuần này": [new Date(moment().startOf("isoweek")), new Date()],
        "Tuần trước": [
          new Date(
            moment()
              .startOf("isoweek")
              .subtract(1, "week")
          ),
          new Date(
            moment()
              .startOf("isoweek")
              .subtract(1, "day")
          )
        ],
        "Tháng này": [
          new Date(moment().startOf("month")),
          new Date(moment().endOf("month"))
        ],
        "Tháng trước": [
          new Date(
            moment()
              .subtract(1, 'months')
              .startOf('month')
              .startOf("month")
          ),
          new Date(
            moment()
              .subtract(1, "month")
              .endOf("month")
          )
        ]
      }
    };
  },
  created() {
    this.ranges = this.calendarMode == "single" ? false : this.rangesDefault;
    // this.alwaysShowCalendars = this.calendarMode == "single" ? true : false;
  },
  watch: {
    from: function(nVal) {
      this.pickerDates = {
        startDate: moment(nVal, "YYYY-MM-DD").toDate(),
        endDate: moment(this.to, "YYYY-MM-DD").toDate()
      };
    },
    to: function(nVal) {
      this.pickerDates = {
        startDate: moment(this.from, "YYYY-MM-DD").toDate(),
        endDate: moment(nVal, "YYYY-MM-DD").toDate()
      };
    }
  },
  computed: {},
  methods: {
    updateValues(dates) {
      this.$emit("date-change", {
        from: moment(dates.startDate).format("YYYY-MM-DD"),
        to: moment(dates.endDate).format("YYYY-MM-DD")
      });
    }
  }
};
</script>
<style css>
@media screen and (max-width: 768px) {
  .daterangepicker.show-ranges .ranges[data-v-1ebd09d2] {
    width: auto !important;
  }
}
.vue-daterange-picker[data-v-1ebd09d2] {
  width: 100%;
}
.reportrange-text[data-v-1ebd09d2] {
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.8rem;
  overflow: unset;
}
@media screen and (min-width: 339px) {
  .daterangepicker.single.show-ranges.show-weeknumbers[data-v-1ebd09d2],
  .daterangepicker.single.show-ranges[data-v-1ebd09d2] {
    min-width: 363px !important;
  }
}
</style>
